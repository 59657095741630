.Container {
    display: flex;
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    background-color: #101010;
    z-index: 100;
}

.Left {
    display: flex;
    align-items: center;
}

.Right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.NavOption {
    padding: 15px 30px;
    cursor: pointer;
    color: white;
    text-decoration: none;
}

.Logo {
    max-height: 40px;
}

.RightSmall {
    display: none;
}

.Hamburger {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.Hamburger img {
    width: 25px;
    object-fit: contain;
}


@media (max-width: 800px) {
    .Right {
        display: none;
    }

    .RightSmall {
        display: flex;
        flex: 1;
        justify-content: flex-end;
    }
}
