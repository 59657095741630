.Container {
    text-align: center;
}

.Container img {
    max-width: 60%;
    width: 400px;
    margin-bottom: 20px;
    margin-left: 60px;
}

.Title {
    font-size: 35px;
    font-weight: 500;
}

.Subtitle {
    font-size: 18px;
    font-weight: 200;
    padding: 20px 0;
}
