@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
    scroll-behavior: smooth;
}

body {
    background-color: #101010;
    min-width: 100vw;
    color: white;
    font-family: 'Outfit';
    margin: 0;
}
