.Container {
    padding: 60px 20px;
    display: flex;
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
}

.Left {
    width: 60%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.ActionButtons {
    display: flex;
    max-width: 100%;
}

.ActionButtons img {
    cursor: pointer;
    height: 45px;
    padding-right: 15px;
}

.Logo {
    max-width: 450px;
}

.Text {
    padding: 40px 0;
}

.PrimaryText, .SecondaryText {
    font-size: 25px;
    font-weight: 200;
    cursor: pointer;
}


.SecondaryText {
    color: #4F90DC;
    padding-top: 15px;
    font-weight: 400;
}

.Right {
    width: 40%;
}

.Right img {
    max-width: 100%;
}


@media (max-width: 800px) {
    .Left {
        width: 100%;
    }

    .Logo {
        max-width: 300px;
    }

    .PrimaryText, .SecondaryText {
        font-size: 20px;
        font-weight: 200;
        cursor: pointer;
    }

    .Right {
        display: none;
    }
}
