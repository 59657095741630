.Container {
    width: 100%;
    max-width: 1040px;
    margin-left: auto;
    margin-right: auto;
    padding: 100px 20px 0 20px;
    box-sizing: border-box;
}


.Header {
    width: 100%;
    display: flex;
    justify-content: center;
}

.HeaderInner {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.Header img {
    height: 100px;
    margin-left: 56px;
    padding-bottom: 20px;
}

.HeaderText {
    width: 100%;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
}


.Questions {
    margin-top: 60px;
}

@media (max-width: 800px) {
    .Header img {
        height: 80px;
        padding-bottom: 20px;
    }
}
