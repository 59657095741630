.Container {
    background-color: #1B1B1B;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.ContainerInner {
    width: 100%;
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    padding: 60px 20px;
    box-sizing: border-box;
    align-items: flex-end;
    z-index: 1;
    flex-wrap: wrap;
}

.Left {
    width: 50%;
    z-index: 1;
}

.Title {
    font-weight: 500;
    font-size: 30px;
}

.Subtitle {
    font-weight: 200;
    padding: 30px 0;
}

.EmailButton {
    background-color: #4F90DC;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 15px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: fit-content;
}

.EmailButton img {
    max-width: 20px;
    margin-left: 10px;
}

.SocialIcons {
    display: flex;
    padding-top: 30px;
}

.SocialIcons img {
    width: 30px;
    margin-right: 15px;
}

.Right {
    height: 100%;
    display: flex;
    font-weight: 200;
    color: rgba(255, 255, 255, 65%);
}

.Seperator {
    margin: 0 10px;
}

.CircleBlue, .CircleGreen {
    position: absolute;
    width: 250px;
    z-index: 0;
}

.CircleBlue {
    bottom: -70px;
    left: -70px;
}

.CircleGreen {
    top: -50px;
    right: -50px;
}

@media (max-width: 800px) {
    .CircleBlue, .CircleGreen {
        position: absolute;
        width: 150px;
        z-index: 0;
    }

    .CircleBlue {
        bottom: -40px;
        left: -40px;
    }

    .CircleGreen {
        top: -20px;
        right: -20px;
    }

    .Left {
        width: 100%;
    }

    .Right {
        width: 100%;
        margin-top: 15px;
    }
}
