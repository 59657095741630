.Container {
    display: flex;
    cursor: pointer;
    align-items: center;
    width: fit-content;
}

.Box, .BoxActive {
    border: 1px solid grey;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.BoxActive {
    background-color: #4f90dc;
    border: none;
}

.Box img, .BoxActive img {
    width: 12px;
}

.Label {
    margin-left: 10px;
}
