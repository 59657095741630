.Container {
    max-width: 700px;
    width: 90vw;
    background-color: #252525;
    padding: 30px;
    box-sizing: border-box;
    position: relative;
}

.CloseIcon {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 5px;
    cursor: pointer;
}

.CloseIcon:hover {
    opacity: .7;
}

.CloseIcon img {
    width: 25px;
    object-fit: contain;
}

.Title {
    font-weight: 500;
    font-size: 25px;
    text-align: center;
}

.Subtitle {
    font-weight: 200;
    font-size: 16px;
    padding: 10px 0;
    text-align: center;
}

.Form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-weight: 300;
    font-size: 14px;
    margin: 20px 0;
}

.Form input, .Form textarea {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 10px;
    outline: none;
    border: none;
    font-size: 15px;
    margin-top: 5px;
    background-color: #353535;
    color: white;
    position: relative;
}

.Form textarea {
    resize: vertical;
    min-height: 100px;
    max-height: 400px;
    font-family: inherit;
}

.FormFieldFull {
    width: 100%;
    padding: 5px 0;
    box-sizing: border-box;
}

.SubmitButton {
    background-color: #4F90DC;
}

.Error {
    color: #D93025;
    margin-top: 3px;
}
