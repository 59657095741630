.Header {
    width: 100%;
    display: flex;
    justify-content: center;
}

.Header {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
}

.HeaderInner {
    text-align: center;
}

.Header img {
    height: 100px;
    margin-left: -75px;
    padding-bottom: 20px;
}

.HeaderText {
    width: 100%;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}


.Cards {
    padding-top: 60px;
    flex-wrap: nowrap;
    overflow-x: auto;
    display: flex;
    padding-left: calc(100vw - 1200px - (100vw - 1200px) / 2);
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.Cards::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}


.Card {
    flex: 0 0 auto;
    padding-right: 50px;
}


@media (max-width: 800px) {

    .Header img {
        height: 80px;
        margin-left: -60px;
        padding-bottom: 20px;
    }

    .Card {
        padding-right: 20px;
    }
}
