.Container {
    max-width: 1040px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 100px 20px 100px 20px;
    box-sizing: border-box;
}

.Header {
    width: 100%;
    display: flex;
    justify-content: center;
}

.HeaderInner {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.Header img {
    height: 100px;
    margin-left: -56px;
    padding-bottom: 20px;
}

.HeaderText {
    width: 100%;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
}


.Content {
    background-color: #252525;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    margin-top: 60px;
    position: relative;
}

.Left {
    width: 60%;
    display: flex;
    align-items: center;
    padding: 50px;
    box-sizing: border-box;
    z-index: 1;
}

.Right {
    width: 40%;
    display: flex;
    justify-content: center;
    z-index: 1;
}

.Title {
    font-size: 40px;
    font-weight: 600;
}

.Subtitle {
    font-weight: 200;
    padding: 20px 0 40px 0;
    box-sizing: border-box;
    font-size: 20px;
}

.Right img {
    max-width: 80%;
    margin-bottom: -50px;
    margin-top: 100px;
}

.ActionButtons {
    display: flex;
    max-width: 100%;
}

.ActionButtons img {
    cursor: pointer;
    height: 40px;
    padding-right: 15px;
}


.CircleBlue, .CircleGreen {
    position: absolute;
    width: 250px;
    z-index: 0;
}

.CircleBlue {
    bottom: -70px;
    left: -70px;
}

.CircleGreen {
    top: -50px;
    right: -50px;
}

@media (max-width: 800px) {
    .Header img {
        height: 80px;
        margin-left: -45px;
        padding-bottom: 20px;
    }

    .Left {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
    }

    .Title {
        font-size: 25px;
        font-weight: 600;
    }

    .Subtitle {
        font-weight: 200;
        font-size: 16px;
    }

    .Right {
        display: none;
    }

    .CircleBlue, .CircleGreen {
        position: absolute;
        width: 150px;
        z-index: 0;
    }

    .CircleBlue {
        bottom: -40px;
        left: -40px;
    }

    .CircleGreen {
        top: -50px;
        right: -50px;
    }
}
