.Container {
    max-width: 1040px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 100px 20px;
    box-sizing: border-box;
}

.HeaderInner {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.Header img {
    height: 100px;
    margin-left: -56px;
    padding-bottom: 20px;
}

.HeaderText {
    width: 100%;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
}


.Section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.SectionText {
    width: 50%;
    display: flex;
    align-items: center;
}

.SectionTitle {
    font-size: 48px;
    font-weight: 600;
}

.SectionSubtitle {
    font-size: 24px;
    font-weight: 300;
    padding-top: 20px;
}

.SectionImage {
    width: 40%;
    display: flex;
    justify-content: flex-end;
}

.SectionImage img {
    max-width: 100%;
}


@media (max-width: 800px) {

    .Header img {
        height: 80px;
        margin-left: -50px;
        padding-bottom: 20px;
    }

    .Section {
        padding: 20px 0;
    }

    .SectionTitle {
        font-size: 25px;
        font-weight: 600;
    }

    .SectionSubtitle {
        font-size: 18px;
        font-weight: 300;
        padding-top: 20px;
    }

    .SectionText {
        width: 40%;
    }

    .SectionImage {
        width: 50%;
    }
}
