.SpinnerContainer {
    height: 100%;
    padding-right: 15px;
    display: flex;
    align-items: center;
}
.Container {
    border: none;
    outline: none;
    padding: 12px 0;
    box-sizing: border-box;
    max-height: 100%;
    max-width: 100%;
    border-radius: 10px;
    cursor: pointer;
    background-color: #38B269;
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    opacity: 1;
    transition: opacity .1s linear;
}

.Container:hover {
    opacity: .8;
    transition: background-color .1s linear;
}

.FullWidth {
    width: 100%;
}

.Label {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ContainerSubmitting {
    opacity: .8;
    cursor: default;
}
