.Container {
    background-color: #252525;
    padding: 50px;
    box-sizing: border-box;
    display: flex;
    border-radius: 20px;
    align-items: center;
    max-width: 700px;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
}

.Left {
    width: 30%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 2;
}

.Header, .Name, .Title {
    width: 100%;
    text-align: center;
}

.Header {
    font-weight: 500;
    font-size: 30px;
}


.Left img {
    width: 120px;
    padding: 20px 0;
    border-radius: 100%;
    height: 120px;
}

.Name {
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 10px;
}

.Title {
    font-weight: 100;
}

.Right {
    width: 60%;
    font-size: 18px;
    font-weight: 100;
    line-height: 1.5;
}


.CircleBlue, .CircleGreen {
    position: absolute;
    width: 200px;
    z-index: 0;
}

.CircleBlue {
    top: -70px;
    left: -70px;
}

.CircleGreen {
    bottom: -100px;
    right: -100px;
}

@media (max-width: 800px) {
    .Container {
        padding: 20px;
        border-radius: 20px;
        max-width: 450px;
    }

    .Header {
        font-weight: 500;
        font-size: 25px;
    }

    .Left img {
        width: 80px;
        height: 80px;
        padding: 15px 0;
    }

    .Name {
        font-size: 16px;
        padding-bottom: 5px;
    }

    .Title {
        font-size: 16px;
        font-weight: 100;
    }

    .Right {
        width: 60%;
        font-size: 14px;
        font-weight: 100;
        line-height: 1.35;
    }


    .CircleBlue, .CircleGreen {
        position: absolute;
        width: 150px;
        z-index: 0;
    }

    .CircleBlue {
        top: -50px;
        left: -50px;
    }

    .CircleGreen {
        bottom: -70px;
        right: -70px;
    }
}
